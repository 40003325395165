<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Accounting</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row">
        <label class="col-4 col-form-label" for="housing-source-sevdesk-id">SevDesk Id</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('sevdeskId').touched || form.get('sevdeskId').dirty) && form.get('sevdeskId').invalid}">
          <input type="text" class="form-control" id="housing-source-sevdesk-id" name="housing-source-sevdesk-id"
                 placeholder="Sevdesk Id" formControlName="sevdeskId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('sevdeskId').errors)">
            {{error}}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- END Sauce -->
